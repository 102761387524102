 .news-one {
     display: flex;
     justify-content: center;

     @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
     }

     .news-one-page {
         max-width: 770px;
         width: 100%;
         margin-right: 100px;

         @media screen and (max-width: 1024px) {
            margin-right: 0;
         }

         &__image {
             background-image: url("/images/news-one.jpg");
             background-position: center;
             background-repeat: no-repeat;
             max-width: 770px;
             width: 100%;
             height: 374px;
             margin-bottom: 14px;

             @media screen and (max-width: 39.9375em) {
                height: 170px;
             }
         }
         &__date { 
             font-family: 'BebasNeue-Bold'; 
             font-size: 16px;
             color: $color-gray;
             margin-bottom: 45px;

             @media screen and (max-width: 39.9375em) {
                padding: 0 10px;
                margin-bottom: 20px;
             }

             @media screen and (min-width: 40em) and (max-width: 1024px) {
                padding: 0 10px;
             }
         }
         &__title  {
             font-family: 'SourceSansPro-Bold';
             font-size: 30px;
             line-height: 38px;
             color: $active-button-color;
             margin-bottom: 35px;

             @media screen and (max-width: 39.9375em) {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 20px;
                padding: 0 10px;
             }

             @media screen and (min-width: 40em) and (max-width: 1024px) {
                padding: 0 10px;
             }
         }
         &__text {
             font-family: 'SourceSansPro-Regular';
             font-size: 16px;
             color: $color-black;
             margin-bottom: 35px;

             @media screen and (max-width: 1024px) {
                padding: 0 10px;
             }
         }
         &__last-image {
             background-image: url("/images/news-one-last.png");
             background-position: center;
             background-repeat: no-repeat;
             max-width: 624px;
             width: 100%;
             height: 368px;
             margin-bottom: 100px;

             @media screen and (max-width: 39.9375em) {
                height: 170px;
                margin-bottom: 30px;
             }
         }
     }
     .news-one-list {
         max-width: 300px;

         @media screen and (max-width: 39.9375em) {
            text-align: center;
            margin-bottom: 30px;
         }

         @media screen and (min-width: 40em) and (max-width: 1024px) {
            text-align: center;
            margin-bottom: 50px;
         }

         .news-one-list-name {
             font-family: 'BebasNeue-Bold'; 
             font-size: 24px;
             color: $active-button-color;
             margin-bottom: 40px;

             @media screen and (max-width: 1024px) {
                display: none;
             }
         }
         .news-one-list-items {

             @media screen and (max-width: 1024px) {
                display: none;
             }

             .news-one-list-items-item {
                 margin-bottom: 25px;
                 &__date {
                     font-family: 'BebasNeue-Bold'; 
                     font-size: 12px;
                     color: $active-button-color;

                 }
                 &__text {
                     font-family: 'SourceSansPro-Regular';
                     font-size: 14px;
                     color: $color-black;
                 }
             }
         }
         .news-one-list-href {
             font-family: 'SourceSansPro-Regular';
             font-size: 12px;
             color: $active-button-color;

             @media screen and (min-width: 40em) and (max-width: 1024px) {
                font-size: 20px;
             }
         }
     }
 }
